import React from "react";
import Layout from "../../Layout";
import "./style.scss";
import SEO from "../../seo";
export default function AdmissionsPage() {
  return (
    <Layout>
      <SEO
        title="Chinmaya Vidyalaya | Admission Guidelines"
        description="View application process and download the admission forms for admissions to classes Prep- XII."
        img="https://www.chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords=""
      />
      <div className="admissions">
        <h1 className="heading">Admission For Other Classes</h1>

        <div className="admissions__section1">
          <div className="row">
            <div className="col col-12 col-lg-6">
              <div className="admissions__section1__left">
                <h2>Classes PREP - IX</h2>
                <p>
                  Admission to PREP-IX is subject to availability of the seats.
                </p>
              </div>
            </div>
            <div className="col col-12 col-lg-6">
              <div className="admissions__section1__right">
                <h2>Classes X AND XII</h2>
                <p>
                  Admission to Class X and XII if any is strictly as per CBSE
                  norms.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="admissions__section2">
          <h2 className="heading">Online Registration For KG – IX</h2>

          <div className="admissions__section2__date">
            1st December 2022 Onwards
          </div>

          <div className="admissions__section2__text">
            Limited seats in class KG to IX
          </div>

          <a
            href="https://cvvvarother.iwsportal.com:572/"
            target="_blank"
            className="btn-dark"
          >
            Click Here for Registration
          </a>

          <div className="tel">
            FOR ADMISSION ENQUIRY CALL -{" "}
            <a href="tel:9999834424">
              <strong>9999834424</strong>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}
